import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { LANGUAGES } from '@app/core/constants/global.constants';
import { Lang } from '@app/core/types/lang.types';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '../base/base.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-language-switcher',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatMenuModule, IconComponent],
  templateUrl: './language-switcher.component.html',
  styleUrl: './language-switcher.component.scss',
})
export class LanguageSwitcherComponent extends BaseComponent {
  readonly languages = LANGUAGES;

  currentLang = this.state.lang;

  langSelectionChangeHandler(lang: Lang) {
    this.changeLanguage(lang);
  }
}
